import React from "react";
import {FaFacebook, FaInstagram, FaLinkedin, FaReddit, FaTelegram, FaTwitter, FaWhatsapp} from "react-icons/fa";
import {SiGlassdoor} from "react-icons/all";

let linkList = [
    {icon: <FaLinkedin size="2.25em" color={"#25649B"} className={"linktree-li-link"}/>, headline: 'Linkedin', url: 'https://il.linkedin.com/company/hunterhr'},
    {icon: <FaFacebook size="2.25em" color={"#4267B2"} className={"linktree-li-link"}/>, headline: 'Facebook', url: 'https://www.facebook.com/HumanResourcesHunter/'},
    {icon: <FaInstagram size="2.25em" className="linktree-li-link linktree-ul-link-fa-instagram"/>, headline: 'Instagram', url: 'https://www.instagram.com/hunter._.hr/'},
    {icon: <SiGlassdoor size="2.25em" color={"#3FB551"} className={"linktree-li-link"}/>, headline: 'Instagram', url: 'https://www.glassdoor.com/Overview/Working-at-Hunter-HR-EI_IE3300455.11,20.htm'},
    {icon: <FaTelegram size="2.25em" color={"#40A5D5"} className={"linktree-li-link"}/>, headline: 'Instagram', url: 'https://t.me/NPhunter'},
    {icon: <FaWhatsapp size="2.25em" color={"#47A81F"} className={"linktree-li-link"}/>, headline: 'Instagram', url: 'https://chat.whatsapp.com/LRGXwogOl2A0kNpUydUsVV'},
    {icon: <FaTwitter size="2.25em" color={"#5EA9DD"} className={"linktree-li-link"}/>, headline: 'Instagram', url: 'https://twitter.com/HunterHR11'},
    {icon: <FaReddit size="2.25em" color={"#F14018"} className={"linktree-li-link"}/>, headline: 'Instagram', url: 'https://www.reddit.com/user/Hunter_hr/'}
]

const Linktree = () => (
    <ul className="linktree-ul">
        {
            linkList.map(({url, icon}, i) => (
                <li className="linktree-li">
                    <a href={url}>
                        {icon}
                    </a>
                </li>
            ))
        }
    </ul>
);

export default Linktree;