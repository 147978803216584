import React from 'react';
import ReactDOM from 'react-dom';
// import AppRouter from "./routers/AppRouter";
import './styles/style.scss'
import 'normalize.css'
import Maintenance from "./components/Maintenance";

const jsx = (
    // <AppRouter />
    <Maintenance/>
)

ReactDOM.render(jsx, document.getElementById('root'))
