import React from "react";
import Linktree from "./Linktree";

const Maintenance = () => (
    <div className="hero-image-maintenance-wrapper">
        <div className="top-left-logo" />
        <div className="hero-image-maintenance" />
        <Linktree/>
    </div>
);

export default Maintenance;